import React from 'react';
import styled from 'styled-components';

const SpecialSvg = styled.svg`
    width: 27px;
    margin: -13px 20px 0px 15px;
`
const ICONS = {
    "facebook": <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Facebook icon</title><path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0"/></svg>,
    "instagram": <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram icon</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>,
    "gmail": <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24"><title>Gmail icon</title><path d="M24 4.5v15c0 .85-.65 1.5-1.5 1.5H21V7.387l-9 6.463-9-6.463V21H1.5C.649 21 0 20.35 0 19.5v-15c0-.425.162-.8.431-1.068C.7 3.16 1.076 3 1.5 3H2l10 7.25L22 3h.5c.425 0 .8.162 1.069.432.27.268.431.643.431 1.068z"/></svg>,
    "s6": <SpecialSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 25 2 C 12.308594 2 2 12.308594 2 25 C 2 37.691406 12.308594 48 25 48 C 37.691406 48 48 37.691406 48 25 C 48 12.308594 37.691406 2 25 2 Z M 25 4 C 36.609375 4 46 13.390625 46 25 C 46 36.609375 36.609375 46 25 46 C 13.390625 46 4 36.609375 4 25 C 4 13.390625 13.390625 4 25 4 Z M 37.5 12 C 35.265625 12 26.421875 13.980469 23.1875 21.160156 C 22.785156 20.664063 21.164063 19 16.992188 19 C 13.621094 19 10 20.125 10 23.5 C 10 28 16.992188 28.25 16.992188 31 C 16.992188 34 13.746094 34 13.746094 34 C 13.746094 34 13.246094 30 10 30 C 9.5 30 8 30.25 8 32 C 8 32.746094 8.601563 33.503906 9.101563 33.851563 C 10.339844 34.71875 11.988281 35 15.25 35 C 20.839844 35 22.394531 32.550781 22.550781 30.40625 C 23.828125 33.890625 27.085938 35 29.734375 35 C 33.734375 35 37.667969 31.742188 38.976563 29.125 C 40.097656 26.875 41.722656 20 34.980469 20 C 31.480469 20 28.984375 21.625 28.984375 21.625 C 28.984375 21.5 29.5 18.625 32.5 15.875 C 34 14.5 35.488281 14.058594 36.375 13.945313 C 37.875 13.75 38.355469 13.480469 38.726563 13 C 39.410156 12.117188 38.75 12 37.5 12 Z M 17.992188 20 C 17.992188 20 17.609375 24 20.988281 24 C 21.410156 24 21.933594 23.835938 22.390625 23.527344 C 22.136719 24.59375 21.988281 25.746094 21.988281 27 C 21.988281 27.246094 22.007813 27.46875 22.019531 27.703125 C 22.011719 27.679688 22 27.644531 21.988281 27.625 C 20.488281 25.125 14.996094 25.125 14.996094 22.5 C 14.996094 19.875 17.992188 20 17.992188 20 Z M 31.09375 23 C 33.453125 22.976563 34.429688 25.457031 33.785156 28.75 C 33.390625 30.796875 31.988281 33.949219 29.808594 34 C 28.613281 34.023438 27.109375 31.921875 27.109375 29.117188 C 27.109375 25.335938 29.421875 23.015625 31.09375 23 Z"/></SpecialSvg>
}
const IconLink = styled.a`
    width: ${p => p.width || 23}px;
    cursor: pointer;
    &:hover {
        fill: rgb(198, 49, 33);
        transform: translateY(-4px);
    }
    transition: .18s linear;

`
export default props => <IconLink 
    target="_blank"
    href={props.link}
    width={props.width}>
    {ICONS[props.icon]}
</IconLink>