import React, {useState} from 'react'
import styled, { keyframes } from 'styled-components'
import { Center as CenterLayout } from '../Styles/Layout'

const SlowSpin = keyframes`
    0%   { transform: rotateZ(0deg)  }
    100% { transform: rotateZ(360deg)  }
`

const Center = styled(CenterLayout)`
    overflow:hidden;
    height: calc(100% - 100px);
    position: relative;
    position: absolute;
`

const DracURL = "https://monica-site-assets.s3-us-west-2.amazonaws.com/brush_stroke_transparent.png"
const LogoURL = "https://monica-site-assets.s3-us-west-2.amazonaws.com/logo_transparent.png"
const TextContainer = styled.div`
    border-radius: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
`
const Name = styled.span`
    padding: 5px;
    border-radius: 10px;
    padding-top: 15px;
    background:  rgba(255, 255, 255, .95);
    font-family: "Josefin Sans";
    font-weight: 300;
    font-size: 60px;
`
const Occupation = styled.span`
    font-size: 17px;
    background:  rgba(255, 255, 255, .95);
    font-weight: 100;
`
const Dracarys = styled.img`
    opacity: ${p => p.isLoaded ? 1 : 0};
    transform: scale(${p => p.isLoaded ? 1 : .98});

    transition: opacity 1.5s ease;
    transition-delay: .5s;
    height: 72vh;
    width: 72vh;
    max-height: 100vw;
    max-width: 100vw;
    position: absolute;
    z-index: -2;
`

const Logo = styled.img`
    max-width: 69.5vw;
    max-height: 69.5vw;
    height: 50vh;
    width: 50vh;
`

export default (props) => {
    const [isLoaded, setLoaded] = useState(false);

    return <Center>
        <TextContainer>
           <Logo src={LogoURL}/>
        </TextContainer>
        <Dracarys 
            isLoaded = {isLoaded}
            onLoad = {() => setLoaded(true)}
            src={DracURL}
        />
    </Center>
}